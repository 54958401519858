@import "@/styles/theme/main.scss";
@import 'common';
@import 'light-theme';
@import 'dark-theme';
@import 'skeleton';
@import 'main';

* {
  font-family: $font-family, 'Inter', sans-serif;
}

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family, 'Inter', sans-serif;

  @include dark-theme;

  @media (prefers-color-scheme: dark) {
    @include dark-theme;
  }

  background-color: $background-default;
  color: $text-primary;
}

a {
  text-decoration: none !important;
}

a:-webkit-any-link {
  text-decoration: none !important;
  color: $text-primary;
}

:root[data-theme='light'] {
  @include light-theme;
}

:root[data-theme='dark'] {
  @include dark-theme;
}

body {
  background-color: $background-default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;

  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    & {
      max-height: 100dvh !important;
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &::-webkit-scrollbar {
    height: 2px !important;
    width: 4px;
    background-color: $background-default;
  }

  &::-webkit-scrollbar-track {
    background-color: $background-default;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    border: 4px solid $text-secondary;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

button {
  border-radius: $border-radius;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.block-scroll {
  overflow-y: hidden !important;
}

.MuiPaper-root {
  @include hide-scrollbar;
}

.MuiPopper-root,
MuiAutocomplete-popper {
  z-index: 9999999999999 !important;
}

.skeleton {
  @include skeleton;

  &--secondary {
    @include skeleton($secondary: true);
  }
}

.desktop-only {
  display: inherit;

  @include media-tablet {
    display: none;
  }
}

.mobile-only {
  display: none;

  @include media-tablet {
    display: block;
  }
}

.react-flow__minimap {
  background-color: $background-paper !important;
  border-radius: 16px;
  width: max-content;
  position: absolute;
  bottom: 16px;
  right: 16px;

  & svg {
    fill: transparent;
  }
}

.react-flow__minimap-node {
  fill: $card-color !important;
}

.react-flow__minimap-mask {
  display: none !important;
}

.react-flow__controls {
  box-shadow: unset;

  & > button {
    background-color: $card-color;
    color: $text-secondary;
    box-shadow: unset;
    cursor: pointer;
    border: unset !important;
    transition: all 0.2s;

    & > svg {
      fill: $text-secondary;
    }

    &:hover {
      background-color: $card-color;
      @include lighen(20%);
    }
  }
}

.react-flow__node {
  width: max-content;
  background-color: $background-paper !important;
  color: $text-primary;
  min-width: 300px;
  padding: 0px !important;
}
