@import "@/styles/theme/main.scss";
.coin,
.text,
.value {
  @include skeleton($secondary: true);
}

.coin {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.text {
  width: 50px;
  height: 14px;
}

.value {
  width: 80px;
  height: 16px;
}
