@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  overflow-x: auto;
  scroll-behavior: smooth;
  cursor: grab;
  height: max-content;
  position: relative;

  @include hide-scrollbar;

  &--dragging {
    cursor: grabbing;
  }

  &--useContainer {
    padding: 8px;
    background-color: $background-default;
    border-radius: $border-radius;
    gap: 1px;
    height: max-content;
  }

  &--invert-colors {
    background-color: $background-paper;
  }

  &--tiny {
    padding: 4px;
    border-radius: $border-radius;
    gap: 1px;
    height: max-content;
    overflow-x: auto;

    @include media-phone {
      width: 100% !important;
    }
  }

  &--max-content {
    width: max-content;
    cursor: unset;
  }

  &--with-indicator {
    gap: unset;
    background-color: unset;
    cursor: pointer;
    padding: unset;
  }

  &__indicator {
    height: 2px;
    background-color: $primary-color;
    position: absolute;
    bottom: 0;
    transition: all 0.3s;
    transform: translateX(-50%);
  }

  &--column {
    flex-direction: column;
  }
}
