@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &--range {
    width: 100%;
    background-color: $background-default;
    border-radius: $border-radius;
    padding-inline: 48px;
    padding-block: 4px;
    padding-top: 6px;
    position: relative;
  }

  &--checkbox {
    width: 24px;
    height: 24px;
    border-radius: $border-radius;
    border: unset;
    outline: unset;
    background-color: $background-paper;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $primary-contrast-text;
    position: relative;
  }

  &--radio {
    width: 24px;
    height: 24px;
    border: unset;
    outline: unset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
  }

  &--switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
    position: relative;
  }

  &--date {
    color-scheme: dark;
  }

  &--color {
    width: 100%;
    height: $box-height;
    border-radius: $border-radius;
    border: unset;
    outline: unset;
    background-color: $background-default !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
  }

  &--date:read-only,
  &--date:disabled {
    width: 100%;
    // pointer-events: none !important;
  }

  &__value {
    position: absolute;

    font-size: 14px;
    font-weight: 700;
    color: $text-primary;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);

    &--right {
      left: unset;
      right: 16px;
      color: $text-secondary;
    }
  }

  &__relative {
    width: 100%;
    position: relative;

    &--radio,
    &--checkbox {
      position: unset;
    }
  }

  &__input-error {
    font-size: 12px;
    font-weight: 500;
    color: $error-color;
  }

  &__figure {
    font-size: 14px;
    color: $text-secondary;
    position: absolute !important;
    left: 16px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    transition: color 0.25s ease-in-out;

    &--right {
      left: unset;
      right: 16px;
    }

    &--radio,
    &--checkbox {
      font-size: 14px;
      left: 28%;
      top: 28%;
      transform: translate(-28%, -28%);
    }

    &--password {
      cursor: pointer;

      &:hover {
        color: $text-primary;
      }
    }
  }

  &__eraser {
    all: unset;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: $background-default;
    background-color: $text-secondary;
    border-radius: 50%;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $primary-color;
    }
  }

  &__input {
    outline: none;
    width: 100%;
    height: $box-height;
    border-radius: $border-radius;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    color: $text-primary;
    background-color: $background-default;
    border: 2px solid $background-default;
    transition:
      color 0.25s ease-in-out,
      border 0.25s ease-in-out;

    &::placeholder {
      transition:
        color 0.25s ease-in-out,
        border 0.25s ease-in-out;
      color: $text-secondary;
    }

    &:not(:read-only) {
      &:hover,
      &:focus {
        color: $text-primary;
        border: 2px solid $primary-color;

        & ~ .container__figure {
          color: $text-primary;
        }
      }

      &:hover::placeholder,
      &:focus::placeholder {
        color: $text-primary;
      }
    }

    &--container-color {
      background-color: $background-paper;
      border: 2px solid $background-paper;
    }

    &--card-color {
      background-color: $card-color;
      border: 2px solid $card-color;
    }

    &--figure,
    &--padding-left {
      padding-left: 42px;

      &--right {
        padding-left: 16px;
        padding-right: 42px;
      }
    }

    &--color {
      width: 100%;
      height: $box-height;
      border-radius: $border-radius;
      position: relative;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        filter: brightness(110%);
        border: 2px solid $primary-color;
      }

      &::-webkit-color-swatch {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: none;
      }

      &::before {
        content: attr(value);
        font-size: 14px;
        font-weight: 700;
        color: $text-primary;
        position: absolute;
        left: 52px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--error {
      border: 2px solid $error-color;

      &:hover,
      &:focus {
        color: $text-primary;
        border: 2px solid $error-color !important;
      }
    }

    &--range {
      all: unset;
      appearance: none;
      height: 15px;
      background-color: $text-secondary;
      width: 100%;
      margin: 0;
      height: 15px;
      border-radius: $border-radius;
      padding-right: unset !important;
      cursor: pointer !important;
      background: linear-gradient(
        to right,
        $primary-color 0%,
        $primary-color calc(var(--position) - 10%),
        $card-color var(--position),
        $card-color
      ) !important;
    }

    &--text-area {
      font-size: 16px;
      resize: none;
      padding-block: 16px;
      height: 80px;

      @include hide-scrollbar;
    }

    &--hidden {
      display: none;
    }

    &__indicator {
      display: flex;
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
      position: absolute;
      background-color: $card-color;
      border: 1px solid $divider-color;
      width: 50px;
      height: 50px;
      border-radius: $border-radius;
      bottom: calc(100% + 15px);
      font-size: 14px;
      font-weight: 700;
      color: $text-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      left: calc(var(--indicator-position) - 14%);

      &::after {
        content: '';
        width: 15px;
        height: 15px;
        background-color: $card-color;
        position: absolute;
        bottom: calc(15px / -2);
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        border: inherit;
        border-top: unset;
        border-left: unset;
      }
    }

    &*:not([type='checkbox']):not([type='radio']):read-only,
    &*:not([type='checkbox']):not([type='radio']):disabled {
      cursor: not-allowed;
      padding-right: 42px;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 15px;
      border-radius: $border-radius;
    }

    &::-webkit-slider-thumb {
      position: relative;
      appearance: none;
      height: 20px;
      width: 20px;
      background: $primary-color;
      border-radius: $border-radius;
      border: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      transition: background-color 150ms;
    }

    &::-moz-range-track,
    &::-moz-range-progress {
      width: 100%;
      height: 30px;
    }

    &::-moz-range-thumb {
      appearance: none;
      margin: 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 0;
      transition: background-color 150ms;
    }

    &::-ms-track {
      width: 100%;
      height: 30px;

      border: 0;
      color: transparent;
      background: transparent;
    }

    &::-ms-thumb {
      appearance: none;
      width: 40px;
      height: 40px;
      background: $text-primary;
      border-radius: 100%;
      border: 0;
      transition: background 150ms;
      top: 0;
      margin: 0;
      box-shadow: none;
    }

    &--checkbox,
    &--radio {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 24px;
      width: 24px;
      z-index: 1;

      &:checked + .container__input__checkmark {
        background-color: $primary-color;
      }

      &:checked + .container__input__checkmark::after,
      &:checked + .container__input__checkmark--radio {
        border: 3px solid $primary-color !important;
        opacity: 1;
      }
    }

    &__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      z-index: 2;
      background-color: $card-color;
      border-radius: $border-radius;
      cursor: pointer !important;
      transition: all 0.3s;
      aspect-ratio: 1;
      pointer-events: none;

      &::after {
        content: '\f00c';
        font-family: 'FontAwesome';
        font-display: block;
        height: 24px;
        color: $primary-contrast-text;
        width: 24px;
        border-radius: $border-radius;
        display: flex;
        line-height: 1rem;
        font-size: 16px;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        opacity: 0;
      }

      &--radio {
        border-radius: 50%;
        border: 1px solid $divider-color;
        background-color: $card-color !important;

        &::after {
          content: '';
          height: calc(22px / 2);
          width: calc(22px / 2);
          border-radius: 50%;
          aspect-ratio: 1;
          background-color: $primary-color;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &--switch {
      opacity: 0;
      width: 50px;
      height: 20px;
      cursor: pointer;

      &:checked + .container__input__switch__slider::before {
        background-color: $primary-color;
        -webkit-transform: translateY(-50%) translateX(24px);
        -ms-transform: translateY(-50%) translateX(24px);
        transform: translateY(-50%) translateX(24px);
      }

      &:checked + .container__input__switch__slider {
        background-color: $primary-ghost-color;
      }
    }

    &__switch__slider {
      position: absolute;
      cursor: pointer;
      padding: unset;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $card-color;
      transition: all 0.4s;
      border-radius: 20px;

      &::before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        top: 50%;
        transform: translateY(-50%) translateX(0px);
        left: 3px;
        background-color: $background-paper;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }

  &__tooltip {
    width: 100%;
    padding: 16px;
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    border: 2px solid $error-color;
    background-color: $background-default;
    border-radius: $border-radius;
    z-index: 2;
    color: $text-primary;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 4px;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: rotate(-45deg);
      background-color: $background-default;
      border-top: 2px solid $error-color;
      border-right: 2px solid $error-color;
    }
  }

  &__formated_value {
    width: 100%;
    height: 80px;
    border-radius: $border-radius;
    background-color: $background-paper;
    padding: 16px;
    padding-right: 44px !important;
    word-break: break-all;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.4rem;

    &--hidden {
      display: none;
    }

    & strong {
      color: $primary-color;
      font-weight: 400;
    }
  }

  &:hover,
  &:focus {
    & .container__input__indicator {
      opacity: 1;
    }
  }
}

.skeleton {
  &--default {
    @include skeleton();
  }

  &--secondary {
    @include skeleton($secondary: true);
  }

  width: 100%;
  height: $box-height;

  &--switch {
    width: 50px;
    height: 20px;
    border-radius: 16px;
  }

  &--radio {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &--checkbox {
    width: 24px;
    height: 24px;
  }
}
