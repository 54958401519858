@import "@/styles/theme/main.scss";
.container {
  all: unset;
  box-sizing: border-box;
  width: 34px;
  min-width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  background-color: $card-color;
  font-size: 14px;
  color: $primary-color;
}
