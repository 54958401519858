@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  background-color: $background-paper;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;

  &--default-color {
    background-color: $background-default;
  }

  &__title {
    display: flex;
    justify-content: center;
    font-size: 36px;
    color: $text-primary;
  }

  &__description {
    font-size: 17px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: $text-primary;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;

    &--around {
      justify-content: space-around;
    }
  }

  &__button {
    max-width: 200px !important;
  }

  &__spacer {
    margin-top: 16px;
  }

  &__code {
    font-size: 24px;
    color: $text-primary;
  }

  &__divider {
    height: auto;
    margin: 0 8px;
    border-right: 1px solid $text-primary;
  }

  &__message {
    font-size: 12px;
    color: $text-secondary;
  }

  &__swoosh {
    width: 100%;
    border-radius: $border-radius;
    height: 100px;
    display: flex;
    margin-right: 16px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-size: 100%;
    transition: all 0.5s ease-in-out;
    top: 0;
    left: 0;
    z-index: 0;

    &:hover {
      background-color: transparent;
      transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
    }
    & i {
      font-size: 36px;
      color: $primary-color;
    }
  }
}
