@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  grid-auto-rows: 1fr;
  gap: 16px;

  &--v2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
}

.error {
  width: 100%;
}
