@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: flex-start;

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
