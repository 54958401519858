@import "@/styles/theme/main.scss";
.container {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: $border-radius;
  transition:
    background-color 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &--loading {
    pointer-events: none;
  }

  &--current {
    background-color: $card-color;

    .container__coin__text {
      color: $text-primary;
    }
  }

  &--without-effect {
    padding: 12px 0;
    cursor: default;
  }

  &:not(.container--disabled) {
    &:hover {
      background-color: $card-color;

      .container__coin__text {
        color: $text-primary;
      }
    }
  }

  &__coin {
    display: flex;
    align-items: center;
    gap: 8px;

    &__text {
      &__container {
        display: flex;
        flex-direction: column;
        font-size: 10px;

        color: $text-secondary;
      }

      font-size: 12px;
      font-weight: 600;
      color: $text-primary;
      transition: color 0.25s ease-in-out;

      &--balance-modal {
        font-size: 14px;
        font-weight: 800;
      }
    }

    &__image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  &__balance {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;

    &__value {
      display: flex;
      align-items: center;

      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      color: $text-primary;

      &--secondary {
        color: $text-secondary;
      }
      &--balance-modal {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &__conversion {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $text-secondary;
    }
  }
}
