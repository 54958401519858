@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: $box-height;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: $border-radius;
  transition:
    background 0.25s ease-in-out,
    color 0.25s ease-in-out,
    filter 0.25s ease-in-out;
  font-size: 13px;
  font-weight: 400;
  margin-top: 4px;
  position: relative;

  background-color: $card-color;
  color: $text-primary;

  &:hover {
    background-color: $background-default;
    @include lighen(50%);
    color: $text-primary;
  }

  &--collapsed {
    padding: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--v3 {
    &:not(.container--collapsed) {
      margin-top: 8px;
      color: $primary-color !important;
      background-color: var(--primary-alpha) !important;

      &:hover {
        @include darken(20%);
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 2px;
        background-color: $primary-color;
      }
    }
  }

  &__icon {
    width: 16px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: inherit;
  }

  &--open {
    background-color: $card-color;
    @include lighen(10%);
    color: $text-primary;
  }

  &--highlight {
    .container__icon {
      color: $primary-color;
    }

    &:hover {
      .container__icon {
        color: $secondary-color;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
  }

  &__chevron {
    all: unset;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border-radius: $border-radius;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;

    & i {
      transform: rotate(0deg);
      transition: ease 0.3s;
      color: $text-primary;
    }

    &--open {
      & i {
        transform: rotate(90deg);
      }
    }
  }
}
