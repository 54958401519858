@import "@/styles/theme/main.scss";
.button {
  &__skeleton {
    width: 120px;
    @include skeleton();
  }
}

.desktop__only {
  @include media-desktop {
    display: none;
  }
}
