@import "@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;

  @include media-tablet {
    height: 34px;
  }

  &__add-balance {
    position: absolute;
    left: -5px;
    bottom: 0;
    z-index: 8;
    font-size: 14px;
    font-weight: 900;
    color: $primary-color;
    opacity: 0;
    animation: moveUp 1.5s forwards;
    white-space: nowrap;

    @keyframes moveUp {
      0% {
        opacity: 1;
        transform: translateY(20px) scale(0);
      }

      25% {
        transform: translateY(20px) scale(1.2);
      }

      35% {
        transform: translateY(20px) scale(1.1);
      }

      50% {
        transform: translateY(20px) scale(1);
      }

      65% {
        opacity: 0.8;
        transform: translateY(20px) scale(0.75);
      }

      75% {
        opacity: 0.4;
        transform: translateY(0px) scale(0.5);
      }

      90% {
        opacity: 0.2;
        transform: translateY(0px) scale(0);
      }

      100% {
        opacity: 0;
      }
    }
  }

  &__tooltip {
    height: 100% !important;
    height: 620px !important;
    :global {
      .MuiPaper-root {
        height: 620px;
        overflow-y: unset !important;

        @include media-tablet {
          position: fixed !important;
          left: 0 !important;
          width: 100dvw;
          min-width: 100dvw;
        }
        @include media-phone {
          background-color: transparent;
          position: absolute !important;
          bottom: 0;
          height: 0px;
          padding: 0;
          margin: 0;
          border: none;
          width: 0 !important;
          min-width: 0 !important;
        }
      }
    }
  }

  &__balance {
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    padding: 12px;
    z-index: 0;
    background-color: $card-color;
    cursor: pointer;
    border-radius: $border-radius 0 0 $border-radius;
    transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: $background-paper;
    }

    @include media-tablet {
      height: 34px;
      gap: 4px;
      padding: 8px;
    }

    &__coin {
      width: 20px;
      height: 20px;
      border-radius: 50%;

      @include media-tablet {
        width: 16px;
        height: 16px;
      }
    }

    &__symbol {
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      color: $text-secondary;

      @include media-desktop {
        font-size: 10px;
      }

      @include media-tablet {
        display: none;
      }
    }

    &__chevron {
      font-size: 14px;
      transform: rotateX(0deg);
      transition: ease 0.3s;
      color: $text-secondary;

      &--open {
        transform: rotateX(180deg);
      }

      @include media-tablet {
        font-size: 12px;
      }
    }

    &__value {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      color: $text-primary;
      position: relative;
      transition: all 0.2s ease-in-out;

      &--crypto {
        min-width: 80px;
      }

      &--fiat {
        min-width: 40px;
      }

      &__decimal {
        color: $text-secondary;
      }

      @include media-tablet {
        display: block;
        font-size: 11px;
        min-width: unset;
        white-space: nowrap;
        width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--invisible {
        display: none;
      }
    }
  }

  &__row {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;

    &--small {
      gap: 4px;
    }

    &--start {
      justify-content: flex-start;
      @include media-tablet {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__deposit {
    all: unset;
    box-sizing: border-box;
    width: fit-content;
    padding: 16px;
    z-index: 1;
    height: 40px;
    background-color: $primary-color;
    color: $primary-contrast-text;
    border-radius: 0px $border-radius $border-radius 0px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @include media-tablet {
      width: 30px;
      min-width: 30px !important;
      height: 34px;

      & span {
        display: none;
      }
    }

    & i {
      margin-top: -2px;

      @include media-tablet {
        font-size: 12px;
        margin-top: 0;
      }
    }

    @include media-large-desktop {
      min-width: 40px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: $secondary-color;
    }
  }
}
