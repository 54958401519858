@import "@/styles/theme/main.scss";
.overlay {
  display: none;

  @include media-phone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    @include hide-scrollbar;
    display: block;
  }
}
.cardContainer {
  background-color: $background-paper;
  width: 400px;
  height: 100%;
  max-height: 620px;
  z-index: 2;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px;
  border: 1px solid rgba($text-secondary, 0.1);
  @include hide-scrollbar;

  @include media-phone {
    max-width: unset;
    width: 100%;
    bottom: 0;
    min-width: 100%;
    z-index: 3 !important;
    position: fixed;
    max-height: none;
    height: 80dvh;
    transition: all 0.2s;
  }

  @include media-tablet {
    width: 90vw;
  }

  &__header-mobile {
    display: none;

    @include media-phone {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 16px;
      padding-inline: 16px;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 12px;
      right: 16px;
      background: none;
      font-size: 16px;
      height: 30px;
      width: 30px;
      padding-inline: 8px;
      cursor: pointer;
      background-color: $card-color;
      border-radius: $border-radius;
      font-size: 12px;
      font-weight: 400;
      color: $text-primary;
    }

    &__title {
      margin: 0;
      flex-grow: 1;
      text-align: center;
      font-weight: 800;
      font-size: 14px;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-inline: 16px;
    padding-bottom: 16px;
    background-color: $background-paper;

    &__row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
