@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-inline: 16px;

    @include hide-scrollbar;

    @include media-phone {
      gap: 16px;
      max-height: 600px;
    }

    &__label {
      position: sticky;
      top: 0;
      background-color: $background-paper;
      font-size: 12px;
      font-weight: 800;
      color: $text-disabled;
      z-index: 10;
      padding: 4px 0;
      margin-bottom: 4px;
      padding-inline: 8px;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
