@import "@/styles/theme/main.scss";
.mobile-only {
  display: none;

  @include media-tablet {
    display: flex;
  }
}

.desktop-only {
  display: flex;

  @include media-tablet {
    display: none;
  }
}
