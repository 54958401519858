@import "@/styles/theme/main.scss";
.indicator {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  width: 18px;
  height: 18px;
  font-size: 9px;
  font-weight: bold;
  border-radius: 50%;
  background-color: $primary-color;
  color: $text-primary;
  top: -5px;
  right: -5px;
}
