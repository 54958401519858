@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  margin-top: 4px;
  padding: 8px 16px;
  border-radius: $border-radius;
  background-color: $card-color;
  position: relative;

  &--collapsed {
    padding: 0;
    background-color: $card-color;

    & i {
      color: $text-primary !important;
    }

    & .container__ticket {
      background-color: unset;

      &::before,
      &::after {
        display: none;
      }
    }

    & .container__ticket__label {
      display: none !important;
    }
  }

  &--v3 {
    &:not(.container--collapsed) {
      margin-top: 8px;
      color: $primary-color !important;
      background-color: var(--primary-alpha) !important;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 2px;
        background-color: $primary-color;
      }
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__ticket {
    width: 100%;
    height: $box-height;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 16px;
    border-radius: $border-radius;
    transition:
      background 0.25s ease-in-out,
      color 0.25s ease-in-out,
      filter 0.25s ease-in-out;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    background-color: $background-default;
    color: $text-secondary;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: $box-height;
      height: 6px;
      background: radial-gradient(
          circle,
          transparent,
          transparent 50%,
          $background-default 50%,
          $background-default 100%
        )
        0px 1px/0.7rem 0.7rem repeat-x;
      transition:
        background 0.25s ease-in-out,
        filter 0.25s ease-in-out;
    }
    &::after {
      left: -25px;
      transform: rotate(90deg);
    }

    &::before {
      right: -25px;
      transform: rotate(-90deg);
    }

    &:hover {
      background-color: $background-default;
      color: $text-primary;
      @include darken(10%);

      &::after,
      &::before {
        content: '';
        @include darken(10%);
        background: radial-gradient(
            circle,
            transparent,
            transparent 50%,
            $background-default 50%,
            $background-default 100%
          )
          0px 1px/0.7rem 0.7rem repeat-x;
      }
    }

    &__icon {
      padding-right: 16px;
      height: 100%;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $primary-color;
      border-right: 5px dotted $background-paper;

      i {
        width: 16px;
        color: $primary-color;
      }
    }

    &__label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      text-align: center;
      color: $primary-color;
    }
  }
}
