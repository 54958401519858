@import "@/styles/theme/main.scss";
.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;

  &--hidden {
    @include landscape {
      display: none;
    }
  }

  &--v2 {
    .container__aside {
      padding-top: unset !important;
    }
  }

  @include media-tablet {
    position: unset;
    top: unset;
    left: unset;
    z-index: unset;
    width: 100%;
    height: 100%;
    min-height: 1px;
  }

  &__aside {
    width: 270px;
    height: 100dvh;
    background: $background-paper;
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition:
      padding 0.3s,
      width 0.25s;

    &--collapsed {
      padding: 16px 0px;
      width: 60px;
    }

    @media (max-width: 768px) {
      padding-top: 0;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 0 24px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    @include media-tablet {
      overflow-y: unset;
    }

    &--collapsed {
      padding: 0 8px;
    }

    ul,
    li {
      list-style-type: none;
    }

    @include hide-scrollbar;

    @include media-tablet {
      padding: 0 16px;
      padding-bottom: 16px;
    }
  }

  &__desktop_only {
    display: block;

    @include media-tablet {
      display: none;
    }
  }

  &__spacer {
    margin-top: 8px;
  }
}
