@import "@/styles/theme/main.scss";
.container {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  min-height: 40px;
  padding: 8px;
  padding-left: 16px;
  margin-top: 4px;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  color: $text-primary !important;
  transition:
    color 0.25s ease-in-out,
    background-color 0.25s ease-in-out,
    filter 0.25s ease-in-out;

  &--collapsed {
    padding: unset;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--current {
    @include lighen(30%);
    background-color: $background-paper;
    color: $primary-color !important;
  }

  &:hover {
    @include lighen(50%);
    background-color: $background-default;
    color: $primary-color !important;
  }

  &--v3 {
    margin-top: 8px;
    background-color: transparent;

    &:hover {
      @include lighen(0%);
      background-color: transparent;
    }
  }

  &__icon {
    width: 16px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--filtered {
      filter: grayscale(100%);
    }
  }

  &__relative {
    position: relative;
  }

  &__label {
    font-size: 13px;
    font-weight: 400;
    max-width: 140px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__new-chip {
    font-size: 10px;
    font-weight: 500;
    color: $primary-color;
    position: absolute;
    right: -25px;
    top: -5px;
  }
}
