@import "@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;
  cursor: pointer;

  &--end {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &:active {
    .switch__button {
      width: 18px;
    }
  }

  &:hover {
    .switch__button {
      opacity: 0.8;
    }
  }

  &__label {
    font-size: 12px;
    font-weight: 500;
    color: $text-secondary;
  }

  .switch {
    width: 40px;
    height: 18px;
    background-color: $divider-color;
    border-radius: 100px;
    position: relative;

    &__input {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    &__button {
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background-color: $text-primary;
      border-radius: 50%;
      transition: all 0.25s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      & i {
        font-size: 10px;
        text-align: center;
        color: $primary-color;
        transition: color 0.25s ease-in-out;
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    &--checked {
      background-color: $primary-ghost-color;

      .switch__button {
        background-color: $primary-color;
        left: calc(100% - 2px);
        transform: translate(-100%, -50%);
      }

      & i {
        color: $text-primary;
      }
    }
  }
}

.skeleton {
  @include skeleton($secondary: true);
  width: 40px;
  height: 18px;
  border-radius: 100px;
}

.label-skeleton {
  @include skeleton($secondary: true);
  width: 40px;
  height: 14px;
}
