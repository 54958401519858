@import "@/styles/theme/main.scss";
.content {
  &__desktop__only {
    display: flex;
    align-items: center;
    gap: 8px;

    @include media-desktop {
      display: none;
    }
  }
}
