@import "@/styles/theme/main.scss";
.coin,
.symbol,
.chevron,
.value {
  @include skeleton($secondary: true);
}

.coin {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.symbol {
  width: 20px;
  height: 14px;
}

.chevron {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.value {
  width: 40px;
  height: 14px;
}

.deposit {
  @include skeleton;
  width: 40px;
  height: 40px;
}
