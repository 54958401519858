@import "@/styles/theme/main.scss";
.container {
  display: flex;
  position: sticky;
  top: 0;
  background-color: $background-paper;
  padding-inline: 16px;
  padding-top: 16px;
  gap: 8px;

  @include media-phone {
    padding-top: 8px;
  }

  &--container-color {
    padding-top: 0;
    background-color: $background-default;

    &::after {
      content: '';
      position: absolute;
      top: -16px;
      left: 0;
      right: 0;
      height: 16px;
      width: 100%;
      background-color: $background-default;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    background-color: $card-color;
    border-radius: $border-radius;
    cursor: pointer;
  }
}
