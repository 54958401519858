@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__collapse {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $background-default;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &--v3 {
      background-color: transparent;
    }
  }
}
