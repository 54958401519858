@import "@/styles/theme/main.scss";
.container {
  padding: 8px !important;

  & > strong {
    color: $primary-color;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 0px;
    position: relative;
    top: 10px;
  }

  & > p {
    font-size: 12px;
    color: $text-secondary;
    font-weight: 400;
  }
}
