@import "@/styles/theme/main.scss";
.tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  background-color: $background-default;
  height: calc($box-height - 8px);
  max-height: calc($box-height - 8px);
  border-radius: $border-radius;
  margin-inline: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &--active {
    background-color: $card-color;
  }

  &--collapsed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    max-height: 40px;
  }

  &__image {
    position: absolute;
    color: $text-secondary;
    font-size: 32px;
    left: -5px;
    bottom: -5px;
    transition: all 0.2s ease-in-out;

    &--active {
      color: $primary-color;
    }

    &--collapsed {
      position: absolute;
      font-size: 18px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 2px;
    }
  }

  &__label {
    font-size: 12px;
    z-index: 1;

    &--collapsed {
      display: none;
    }
  }
}
