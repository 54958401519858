@import "@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;

  &--unset-height {
    height: unset;
  }
}
