@import "@/styles/theme/main.scss";
.container {
  @include media-tablet {
    position: fixed;
    right: 16px;
    bottom: 130px;
  }
}

.link {
  all: unset;
  box-sizing: border-box;
  width: 220px;
  height: 48px;
  cursor: pointer;
  pointer-events: all;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-radius: $border-radius;
  transition:
    background 0.25s ease-in-out,
    opacity 0.25s ease-in-out,
    color 0.25s ease-in-out;
  color: $text-secondary;
  position: relative;
  overflow: hidden;

  @include media-tablet {
    width: 200px;
    height: unset;
    box-shadow: 0 0 10px $primary-ghost-color;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    opacity: 0.3;
    pointer-events: none;
    width: 200%;
    height: 100%;
    transition: width 0.5s;
    background: radial-gradient(circle, $primary-color, $card-color 45%);

    @include media-tablet {
      opacity: 1;
      width: 100%;
      transform: translateY(-50%);
      border-radius: $border-radius;
      left: 0;
      background: radial-gradient(
        circle at left,
        $primary-color,
        $card-color 30%
      );
    }
  }

  &:hover {
    &::after {
      width: 500%;
    }
    color: $text-primary;
  }

  &__wrapper {
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
      white-space: nowrap;
      color: $text-primary;
    }

    &__description {
      font-size: 10px;
      line-height: 12px;
      font-weight: 600;
      white-space: nowrap;
      color: $text-secondary;
    }
  }

  &__lottie {
    width: 100%;
    z-index: 1;
    max-width: 80px;
    position: absolute;
    left: -10%;
    bottom: -50%;
    pointer-events: none;

    &__placeholder {
      all: unset;
      min-width: 30px;

      @include media-tablet {
        min-width: 10px;
      }
    }

    @include media-tablet {
      max-width: 100px;
      left: -21%;
      bottom: -60%;
    }
  }

  &__icon {
    min-width: 24px;
    height: 24px;
    background: linear-gradient(
      135deg,
      $primary-color 0%,
      $secondary-color 100%
    );
    box-shadow:
      inset 0px 0px 1px $text-primary,
      inset 2px 2px 1px $primary-color,
      inset -2px -2px 1px $primary-color,
      1px 1px 3px $primary-color,
      -1px -1px 3px $primary-color;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
    color: $primary-contrast-text;
    font-size: 14px;
    border-radius: $border-radius;
    transition: all 0.25s ease-in-out;

    &:hover {
      @include lighen(10%);
      color: $text-primary !important;
    }
  }

  &__close {
    all: unset;
    box-sizing: border-box;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 5;
    width: 18px;
    height: 18px;
    font-size: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $text-secondary;
    color: $background-default;

    @include media-tablet {
      display: flex;
    }
  }
}
