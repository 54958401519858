@import "@/styles/theme/main.scss";
.container {
  height: 80px;
  background-color: $background-default;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;
  transition:
    left 0.25s,
    width 0.25s,
    top 0.25s,
    opacity 0.25s,
    padding 0.25s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 270px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--omit {
    top: -68px;
  }

  &--hidden {
    @include landscape {
      display: none;
    }
  }

  &--collapsed {
    left: 60px;
  }

  &--v2 {
    background-color: $background-paper;
    box-shadow: 0 4px 2px -2px $background-default;
  }

  @include media-tablet {
    left: 0;
    height: 68px;
  }

  &__content {
    width: 100%;
    max-width: 1200px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 0 24px;

    @include media-tablet {
      padding: 0 16px;
      height: 68px;
    }

    &__wrapper {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-start;

      @include media-tablet {
        display: none;
      }
    }

    &__tab_wrapper {
      overflow-x: hidden !important;

      &--with-spacer {
        margin-left: 8px;
      }

      @include media-tablet {
        display: none;
      }
    }

    &__row {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &__logo {
      width: 120px;

      &--collapsed {
        width: 30px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    &__mobile {
      display: none;
      align-items: center;
      gap: 8px;

      & a {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include media-tablet {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__logo {
        width: 110px;
        position: relative;
        top: -2px;
      }
    }
  }

  &__mobile-only {
    display: none;

    @include media-tablet {
      display: block;
    }
  }

  &__desktop-only {
    display: block;

    @include media-tablet {
      display: none;
    }
  }

  &__toggle {
    position: fixed;
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    background-color: $background-default;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $text-primary;
    z-index: 1110;
    transition: all 0.25s;

    &--sticky {
      top: 0px;
    }
  }
}
