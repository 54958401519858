@import "@/styles/theme/main.scss";
.button {
  all: unset;
  box-sizing: border-box;
  width: fit-content;
  height: $box-height;
  padding: 0 16px;
  border-radius: $border-radius !important;
  position: relative;
  white-space: nowrap;

  background-color: $background-default;
  font-size: 14px;
  font-weight: 500;
  color: $text-secondary;

  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out,
    filter 0.25s ease-in-out !important;

  &:disabled {
    pointer-events: none;
  }

  cursor: pointer;

  &--active {
    color: $text-primary;
    background-color: $card-color;
  }

  &--invert-colors {
    background-color: $background-default;
  }

  &--with-indicator {
    all: unset;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: center;
    height: $box-height;
    background-color: unset !important;
  }

  &--tiny {
    height: unset;
    padding: 8px 12px;
    width: max-content !important;
    min-width: 30px;

    & .button__content {
      font-size: 10px;
    }
  }

  &--max-content {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    & .button__content {
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
    }
  }

  &--small {
    height: 40px;
    font-size: 12px;
    font-weight: 400;

    &--active {
      background-color: $card-color;
    }
  }

  &--disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover:not(.button--disabled) {
    color: $text-primary;
    background-color: $card-color;
  }

  @include media-tablet {
    &:not(.button--tiny) {
      height: 40px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }

  &--container {
    &:is(:nth-child(n + 2)) {
      border-radius: unset;

      &::after {
        border-radius: unset;
      }
    }

    &:is(:last-child) {
      border-radius: 0px $border-radius $border-radius 0px;

      &::after {
        border-radius: unset;
      }
    }
    &:is(:first-child) {
      border-radius: $border-radius 0px 0px $border-radius;

      &::after {
        border-radius: unset;
      }
    }
  }
}
