@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  background-color: $card-color;
  padding: 16px;
  height: fit-content;
  border-radius: $border-radius;

  @include media-tablet {
    padding: 8px 16px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    white-space-collapse: preserve;
    color: $text-primary;
  }

  &__description {
    width: 50%;
    font-size: 14px;
    font-weight: 700;
    color: $text-secondary;

    @include media-tablet {
      font-size: 12px;
    }
  }

  &__title,
  &__description {
    width: 100%;
    max-width: 70%;
    z-index: 2;
  }

  &__lottie {
    position: absolute;
    width: 150px;
    z-index: 2;
    right: -11.5%;
    bottom: -50%;
    transform: rotate(-45deg);

    @include media-tablet {
      bottom: -52%;
      right: -16%;
    }
  }

  &::before,
  &::after {
    position: absolute;
    left: 0%;
    top: 0%;
    content: '';
    width: 300%;
    overflow: hidden;
    border-radius: $border-radius;
    height: 300%;
  }

  &::after {
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 1px solid $card-color;
    background: linear-gradient(145deg, $card-color 15%, transparent 200%);
  }

  &::before {
    z-index: 0;
    opacity: 0.8;
    left: 2px;
    background:
      radial-gradient($primary-ghost-color, $primary-color 80%),
      repeating-conic-gradient(
          $primary-ghost-color 0 10deg,
          $primary-color 0 20deg
        )
        100% no-repeat;
  }
}
