@import "@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 24px;
  height: 80px;

  &--collapsed {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 0 8px;
  }

  @include media-tablet {
    padding: 0 16px;
  }

  &__link {
    width: 100%;
    max-width: 180px;

    &--collapsed {
      width: 32px;
    }
  }

  &__mobile-logo {
    display: none;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;

    &--collapsed {
      display: flex;
    }
  }

  &__logo {
    width: 100%;
    max-width: 140px;
    cursor: pointer;
    display: block;

    &--collpased {
      display: none !important;
    }
  }

  &--variant2 {
    gap: 4px;
    box-shadow: 0 4px 2px -2px $background-default;
  }
}
