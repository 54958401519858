@import "@/styles/theme/main.scss";
.icon {
  all: unset;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: $border-radius;
  background-color: $card-color;
  color: $text-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  flex-shrink: 0 !important;
  transition: background-color 0.25s ease-in-out;

  &--icon-only {
    width: unset;
    height: unset;
    background-color: unset;
    border-radius: unset;

    &:hover {
      color: $text-primary;
    }
  }

  &--small {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }

  &:hover {
    background-color: $background-paper;
  }

  &:disabled {
    pointer-events: none;
  }

  &--container {
    background-color: $background-paper;
    transition: all 0.3s;

    &:hover {
      @include lighen(20%);
    }
  }
}

.skeleton {
  @include skeleton();
  width: 40px;
  height: 40px;
}
