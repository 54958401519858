@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  padding: 16px 8px;
  background: var(--frame-bg);
  background-size: 100% 100%;
  border-radius: $border-radius;
  position: relative;
  overflow: hidden;
  border-radius: $border-radius;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  min-height: 55px;

  &:hover {
    @include lighen(20%);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--frame);
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    pointer-events: none;
    z-index: 2;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;

    &__lottie {
      width: 100%;
      max-width: 80px;
      position: absolute;
      left: -40px;
      bottom: -40px;

      &__placeholder {
        width: 100%;
        min-width: 1px;
        max-width: 40px;
      }
    }

    &__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      z-index: 1;
      position: relative;

      &__title {
        font-size: 14px;
        font-weight: 500;
        color: $text-primary;
      }

      &__description {
        font-size: 12px;
        color: $text-secondary;
        @include lighen(35%);
      }
    }
  }
}
