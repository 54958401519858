@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $card-color;
  padding: 16px;
  border-radius: $border-radius;

  &--v2 {
    background-color: transparent;
    padding: 0;
    gap: 8px;
  }

  &__icon {
    width: 16px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-color;
  }

  &__title {
    font-size: 13px;
    font-weight: 600;
    color: $text-primary;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
