@import "@/styles/theme/main.scss";
.container {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  &--secondary {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    overflow-x: unset;
  }

  @include media-tablet {
    width: 100%;
  }

  &__tab {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    border-radius: $border-radius;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;

    color: $text-primary;
    font-size: 12px;
    font-weight: 500;

    transition: background-color 0.25s ease-in-out;

    &:disabled {
      pointer-events: none;
    }

    &:hover:not(.button--disabled) {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      opacity: 0;
      width: 100%;
      height: 2px;
      background-color: $primary-color;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.3s;
    }
  }

  &__indicator {
    height: 2px;
    background-color: $primary-color;
    position: absolute;
    bottom: 0;
    transition: all 0.3s;
    transform: translateX(-50%);
  }
}
