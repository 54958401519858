@import "@/styles/theme/main.scss";
.link {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  height: $box-height;
  min-height: $box-height;
  cursor: pointer;
  pointer-events: all;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: $border-radius;
  transition:
    background 0.25s ease-in-out,
    opacity 0.25s ease-in-out,
    color 0.25s ease-in-out;
  color: $text-secondary;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    transition: width 0.5s;
    background: linear-gradient(150deg, $primary-color, $card-color 45%);
  }

  &:hover {
    &::after {
      width: 500%;
    }
    color: $text-primary;
  }

  &__wrapper {
    z-index: 2;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      color: $text-primary;
    }

    &__description {
      font-size: 10px;
      line-height: 10px;
      font-weight: 600;
      color: $text-secondary;
    }
  }

  &__lottie {
    width: 100%;
    z-index: 1;
    max-width: 100px;
    position: absolute;
    left: -20%;
    bottom: -50%;
    transform: rotate(45deg);

    &__placeholder {
      all: unset;
      width: 100%;
      max-width: 50px;
      content: '';
    }
  }
}
