@import "@/styles/theme/main.scss";
.container {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: end;

  @include media-tablet {
    display: none;
  }

  &__icon {
    font-size: 16px;
  }

  &__button {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-secondary;
    background: $background-default;
    border-radius: $border-radius;
    transition:
      color 0.25s ease-in-out,
      background-color 0.25s ease-in-out,
      filter 0.25s ease-in-out;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: $text-primary;
      background-color: $background-default;
      @include lighen(40%);
    }
  }

  &__icon-button {
    all: unset;
    box-sizing: border-box;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: $text-secondary;
    transition:
      color 0.25s ease-in-out,
      background-color 0.25s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $background-default;
      color: $text-primary;
    }
  }
}
